import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Slices/authSlice";
import Swal from "sweetalert2";

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth);
  // console.log(loading);
  // const error = useSelector((state) => state.auth.error);
  const location = useLocation()
  const redirectPath = location.state?.redirectPath || "/";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(loginUser({ email, password }));
      if (response?.error) {
        Swal.fire({
          icon: "error",
          title: `${response?.payload?.message}`,
        })
      } else {
        if (redirectPath) {
          navigate(redirectPath);
        }else{
          navigate("/")
        }
      }
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  };

  const navigate = useNavigate();
  const handleXMark = () => {
    navigate("/");
  };

  // removing token for success reset pwd
  function handleForgot(){
   localStorage.clear()
  }
  return (
    <div className="main-wrapper authendication-pages">
      <div className="content">
        <div className="container wrapper no-padding">
          <div className="row no-margin vph-100">
            <div className="col-12 col-sm-12 col-lg-6 no-padding">
              <div className="banner-bg login">
                <div className="row no-margin h-100"></div>
              </div>
            </div>
            <div className="col-12 col-sm-12  col-lg-6 no-padding">
              <div className="dull-pg">
                <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <div className="d-flex justify-content-end pt-3">
                      <i
                        className="fa-solid fa-xmark"
                        style={{ fontSize: "24px", cursor: "pointer" }}
                        onClick={handleXMark}
                      />
                    </div>
                    <header className="text-center">
                      <a href="user-dashboard.html">
                        <img src={logo} className="img-fluid w-25" alt="Logo" />
                      </a>
                    </header>
                    <div className="shadow-card">
                      <h2>Welcome Back</h2>
                      <p>Login into your account</p>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="user-tab"
                        >
                          {/* Login Form */}
                          <form onSubmit={handleLogin}>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-user" />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="pass-group group-img">
                                <i className="toggle-password feather-eye-off" />
                                <input
                                  type="password"
                                  className="form-control pass-input"
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group d-sm-flex align-items-center justify-content-between">
                              <span>
                                <Link
                                  to="/auth/forgotPassword"
                                  className="forgot-pass"
                                  onClick={handleForgot}
                                >
                                  Forgot Password
                                </Link>
                              </span>
                            </div>
                            <button
                              className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                              // onClick={handleLogin}
                              type="submit"
                              disabled={loading === "pending"}
                            >
                              {loading === "pending" ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Sign In"
                              )}
                              <i className="feather-arrow-right-circle ms-2" />
                            </button>
                          </form>
                          {/* /Login Form */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coach"
                          role="tabpanel"
                          aria-labelledby="coach-tab"
                        ></div>
                      </div>
                      <div className="text-center">
                      <p>
                        Don’t have an account?{" "}
                        <Link to="/auth/register" className="text-primary">Sign up!</Link>
                      </p>
                    </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

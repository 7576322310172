import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({title , page}) => {
  return (
    <section className="breadcrumb breadcrumb-list mb-0">
      <span className="primary-right-round" />
      <div className="container">
        <h1 className="text-white">{title}</h1>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>{page}</li>
        </ul>
      </div>
    </section>
  );
};

export default Breadcrumb;

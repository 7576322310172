import React from 'react';
import Navbar from '../Navbar/Navbar';
import Breadcrumb from '../Common/Breadcrumb';
import Footer from '../Footer/Footer';

const Refund = () => {
    return (
        <>
        <Navbar />
        <Breadcrumb title={`Refund Policy`} page={`Refund Policy`} />
        <div class="content">
          <div class="container">
          <h3>Effective Date: February 15, 2024</h3>
            <p>
            Thank you for choosing Pro Padel for your pickle and paddle court reservations. We strive to provide you with a seamless booking experience and outstanding customer service. Please read our refund policy carefully before making any reservations.
            </p>
            <ul className="policy">
              <strong>1. No-Show Policy</strong>
              <li>No Refund for No-Show: Pro Padel does not provide refunds for bookings where the customer fails to arrive at the scheduled time to avail the service. However due to certain emergency reason if there is a no show, we might consider the situation and grant the next available slot.</li>
        
            </ul>

            <ul className="policy">
              <strong>2. Changes in Booking</strong>
              <li>No Refunds for Future Booking Changes: Any changes made to your booking for future dates or times will not be eligible for refunds. However, we understand that plans may change, and we will do our best to accommodate your needs as per the court's availability.</li>
              <li>Next Available Slot: If you need to change your booking for a future date or time, please contact us 2 hours before your confirmed booking start time, and we will assist you in rescheduling your reservation to the next available slot based on availability of court and Pro Padel's convenience.</li>

            </ul>

            <ul className="policy">
              <strong>3. Exceptions</strong>
              <li>Facility Closure: In the event that the facility where you booked your court reservation closes unexpectedly, Pro Padel will provide a full refund for the affected booking.</li>
            </ul>

            <ul className="policy pb-5">
              <strong>4. Contact Us</strong>
              <li>If you have any questions, concerns, or require assistance regarding our refund policy, please don't hesitate to contact us at propadelahmedabad@gmail.com. We are here to help and ensure your experience with Pro Padel exceeds your expectations.</li>
            </ul>
  
            <strong>Thank you for choosing Pro Padel for your pickle and paddle court bookings. We appreciate your trust in us.</strong>

          </div>
        </div>
        <Footer />
      </>
    );
}

export default Refund;

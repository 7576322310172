/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./nav.css";
import logo from "../../assets/img/logo.png";
import { Link , useLocation, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllGames } from "../../Redux/Slices/gameSlice";
import { logoutUser } from "../../Redux/Slices/authSlice";
import Swal from "sweetalert2";

const Navbar = () => {
  const { token } = useSelector(state => state.auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [dropdownopen, setdropdownopen] = useState(false);
  const location = useLocation()
  const navigate = useNavigate()

  const isNavLinkActive = (path) => {
    return location.pathname === path;
  };

  function handleLogout() {
    if (token) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "Are you sure you want to logout ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(logoutUser())
          navigate("/")
        } 
      });
    } else {
      console.log("token doesnt exist");
    }
  }

  const dispatch = useDispatch();
  const games = useSelector((state) => state?.allGames?.games);

  useEffect(() => {
    dispatch(getAllGames());
  }, [dispatch]);

  function hasSubmenu() {
    setdropdownopen(!dropdownopen);
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`header ${isMenuOpen ? "menu-opened" : ""} ${
        isHeaderFixed ? "fixed" : ""
      }`}
    >
      <div className="container-fluid px-5">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <button id="mobile_btn">
              <span className="bar-icon" onClick={toggleMenu}>
                <span />
                <span />
                <span />
              </span>
            </button>
            <Link to="/" className="navbar-brand logo">
              <img src={logo} className="img-fluid main-nav-logo" alt="Logo" />
            </Link>
          </div>
          <div
            className={`main-menu-wrapper ${isMenuOpen ? "menu-opened" : ""}`}
          >
            <div className="menu-header">
              <Link to="/" className="menu-logo">
                <img src={logo} className="img-fluid w-25" alt="Logo" />
              </Link>
              <a id="menu_close" className="menu-close">
                <i className="fas fa-times" onClick={toggleMenu} />
              </a>
            </div>
            <ul className="main-nav">
              <li className={isNavLinkActive("/") ?  "active" : ""}>
                <Link to="/">Home</Link>
              </li>

              <li className="has-submenu">
                <a onClick={hasSubmenu}>
                  Book Now <i className="fas fa-chevron-down" />
                </a>
                <ul className={`submenu ${dropdownopen ? "opendropdown" : ""}`}>
                  {games?.map((game) => (
                      <li key={game._id} >
                         
                        <Link
                          to={`/game/${game.game_Name}/${game._id}`}
                          key={game._id}
                          className="all-game-name"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          {game.game_Name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
              <li className={isNavLinkActive("/gallery") ?  "active" : ""}>
                <Link to="/gallery" >Gallery</Link>
              </li>
              <li className={isNavLinkActive("/gameRules") ?  "active" : ""}>
                <Link to="/gameRules" >Rules</Link>
              </li>
              <li className={isNavLinkActive("/about") ?  "active" : ""}>
                <Link to="/about" >About Us</Link>
              </li>
              <li className={isNavLinkActive("/contact") ?  "active" : ""}>
                <Link to="/contact" >Contact Us</Link>
              </li>
              {token ? (
                <>
                  <li className="login-link" >
                    <Link to="/profile" >Profile</Link>
                  </li>
                  <li className="login-link">
                    <Link onClick={handleLogout}>Log out</Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="login-link">
                    <Link to="/auth/login" >Sign In</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              {token ? (
                <div className="nav-link">
                  <div className="dropdown">
                    <button
                      className="btn btn-white log-register dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-user"></i>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <Link to="/profile" className="dropdown-item">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          Log out
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <Link
                  className="nav-link btn btn-white log-register"
                  to="/auth/login"
                >
                  Login
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;

import React from "react";
import { useLocation, Link } from "react-router-dom";

const ProfileTabs = () => {
  const location = useLocation();

  const isNavLinkActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div>
      <div className="dashboard-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-menu">
                <ul>
                  <li>
                    <Link
                      to="/profile"
                      className={isNavLinkActive("/profile") ? "active" : ""}
                    >
                      <i
                        class="fa-solid fa-user"
                        style={{ fontSize: "30px" }}
                      ></i>
                      <span className="pt-2">Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/MyBookings"
                      className={isNavLinkActive("/MyBookings") ? "active" : ""}
                    >
                      <i
                        class="fa-solid fa-calendar-days"
                        style={{ fontSize: "30px" }}
                      ></i>
                      <span className="pt-2">My Bookings</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTabs;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="footer">
        <div className="container">
          {/* Footer Top */}
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Contact us</h4>
                  <div className="footer-address-blk">
                    <div className="footer-call">
                      <p>+91 9510496090</p>
                      <p>+91 8849788752</p>
                    </div>
                    <div className="footer-call">
                      <span>Need Live Support ?</span>
                      <br />
                      <a href="mailto:someone@example.com" className="text-white">propadelahmedabad@gmail.com</a> 
                    </div>
                  </div>
                  <div className="social-icon">
                    <ul>
                      
                      <li>
                        <a className="instagram" href="https://www.instagram.com/propadel.ahmedabad/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/gameRules">Rules</Link>
                    </li>
                  </ul>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Support</h4>
                  <ul>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/t&c">Terms &amp; Conditions</Link>
                    </li>
                    <li>
                      <Link to="/refund-policy">Refund Policy</Link>
                    </li>
                  </ul>
                </div>
                {/* /Footer Widget */}
              </div>

              <div className="col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu">
                  <h4 className="footer-title">Steps to Download</h4>
                   <ul>
                    <li>1. Open your browser </li>
                    <li>2. Go to our website - www.propadel.co.in</li>
                    <li>3. Tap on the menu or options </li>
                    <li>4. Select "Add to Home Screen."</li>
                    <li>5. Confirm by tapping "Add" or "Install."</li>
                    <li>6. The app icon will appear on your home screen.</li>
                    <li>7. Tap the icon to launch the web app.</li>
                  </ul> 
                </div>
                {/* /Footer Widget */}
              </div>
            </div>
          </div>
          {/* /Footer Top */}
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="copyright-text">
                <p className="mb-0">© 2023 propadel - All rights reserved.</p>
                <a
                  href="https://uptakeinfotech.com/"
                  target="_blank"
                  className="mb-0 company-link"
                  rel="noreferrer"
                >
                  Powered by - Uptake Infotech Pvt. Ltd.
                </a>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>
      {/* /Footer */}
    </>
  );
};

export default Footer;

import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Breadcrumb from "../Common/Breadcrumb";
import court from "../../assets/img/about/manpadel.jpg";
import picklebanner from "../../assets/img/about/pickleballd.jpg";
import banner3 from "../../assets/img/about/standracket.jpg";

const About = () => {
  return (
    <>
      <Navbar />
      {/* Breadcrumb */}
      <Breadcrumb title="About us" page="About Us" />
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content">
        {/* About Us Info */}
        <section className="aboutus-info">
          <div className="container">
            {/* Banners */}
            <div className="row d-flex align-items-center">
              <div className=" col-12 col-sm-3 col-md-3 col-lg-3">
                <div className="banner text-center">
                  <img
                    src={court}
                    className="img-fluid corner-radius-10 aside-abt-banner"
                    alt="Banner-01"
                  />
                </div>
              </div>
              <div className=" col-12 col-sm-6 col-md-6 col-lg-6">
                <div className="banner text-center">
                  <img
                    src={picklebanner}
                    className="img-fluid corner-radius-10"
                    alt="Banner-02"
                  />
                </div>
              </div>
              <div className=" col-12 col-sm-3 col-md-3 col-lg-3">
                <div className="banner text-center">
                  <img
                    src={banner3}
                    className="img-fluid corner-radius-10 aside-abt-banner"
                    alt="Banner-03"
                  />
                </div>
              </div>
            </div>
            {/* /Banners */}
            {/* Vision-Mission */}
            <div className="vision-mission">
              <div className="row">
                <div className=" col-12 col-sm-12 col-md-12 col-lg-8">
                  <h2>
                    Experience Tennis Excellence at Propadel Ahmedabad - Reserve
                    Your Court Now!
                  </h2>
                  <p>
                    At Propadel Ahmedabad, we are dedicated to providing a
                    premier tennis experience for players of all ages and skill
                    levels. Our state-of-the-art facilities, exceptional
                    customer service, and vibrant tennis community set us apart
                    as the leading tennis destination in Ahmedabad.
                  </p>
                  <p>
                    At Propadel Ahmedabad, we are committed to providing you
                    with the ultimate tennis experience. With our exceptional
                    facilities, passionate staff, vibrant community, and
                    affordable pricing, we strive to exceed your expectations
                    every step of the way. Join us today and embark on a tennis
                    journey filled with fun, improvement, and camaraderie.
                    Discover why Propadel Ahmedabad is the preferred choice for
                    tennis enthusiasts in the city.
                  </p>
                </div>
                <div className=" col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mission-bg">
                    <h2>Facilities:</h2>
                    <p>
                      Our courts are meticulously designed and maintained
                      to meet the highest standards. We prioritize the quality
                      of our playing surfaces, ensuring optimal performance and
                      safety for our players. With well-equipped and comfortable
                      seating areas, we create a welcoming environment where you
                      can enjoy your game to the fullest. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /Vision-Mission */}
          </div>
        </section>
        {/* /About Us Info */}

      
      </div>
      {/* /Page Content */}
      <Footer />
    </>
  );
};

export default About;

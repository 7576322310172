/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import Slider from "react-slick";
import Breadcrumb from "../Common/Breadcrumb";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { getAllGameCourtsById } from "../../Redux/Slices/gameSlice";
import {
  checkTimeSlotAvailability,
  fetchCourtTimeSlots,
} from "../../Redux/Slices/slotSlice";
// import { getToken } from "../../Api/api";
import CourtAccordion from "./CourtAccordian";

const Booking = () => {
  const navigate = useNavigate();
  const { gamename, id } = useParams();
  const dispatch = useDispatch();

  // const error = useSelector(state => state.allGames)
  // console.log(error, "error from useselect")

  // state variables
  const [selectedGame, setselectedGame] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [selectedDate, setselectedDate] = useState(null);
  const [price, setprice] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [bookedTimeSlots, setbookedTimeSlots] = useState();
  const [expandedCourt, setExpandedCourt] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(60);
  const timeslotSectionRef = useRef(null);
  const sliderRef = useRef(null);
  const timesliderRef = useRef(null);

  // use-selecter
  const courts = useSelector((state) => state?.allGames?.courts);
  const gameError = useSelector((state) => state.allGames.error);

  // const server_timeslot_error = useSelector(state => state.slots.error)
  // console.log(server_timeslot_error,"hetttt")

  // duration
  const durationOptions = [60 , 90 , 120, 180];

  // Effects hooks
  useEffect(() => {
    // Check if the screen width is below a certain breakpoint (e.g., 768 pixels)
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);
    // Initial check on component mount
    handleResize();
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllGameCourtsById(id));
      setselectedGame(gamename);
    };

    fetchData();
  }, [dispatch, gamename, id, navigate]);

  useEffect(() => {
    if (courts.length > 0) {
      setSelectedCourt(courts[0]);
      setExpandedCourt(courts[0]);
      if (!selectedDate) {
        const firstAvailableDate = getSevenDays(courts[0].days)
          .filter((day) => day.enabled)
          .map((day) => day.date)[0];
        setselectedDate(firstAvailableDate);
      }
    }
  }, [courts]);
  

  useEffect(() => {
    if (selectedCourt) {
      // Set the price
      setprice(selectedCourt.price);

      // Generate time slots
      const startTime = selectedCourt.from;
      const endTime = selectedCourt.to;
      const generatedTimeSlots = generateTimeSlots(startTime, endTime);
      setTimeSlots(generatedTimeSlots);
      setSelectedTimeSlots([]);
    } else {
      setprice(null);
      setselectedDate(null);
      setTimeSlots([]);
      setSelectedTimeSlots([]);
    }
  }, [selectedCourt]);

  useEffect(() => {
    const fetchBookedData = async () => {
      try {
        const response = await dispatch(
          fetchCourtTimeSlots({
            courtId: selectedCourt?._id,
            date: selectedDate,
          })
        );
        setbookedTimeSlots(response?.payload?.timeslots);
        window.scrollTo({
          top: timeslotSectionRef.current.offsetTop,
          behavior: "smooth",
        });
      } catch (error) {
        console.error("Error fetching time slots:", error);
        // If there's an error, set bookedTimeSlots to an empty array
        setbookedTimeSlots([]);
      }
    };

    if (selectedCourt && selectedDate) {
      fetchBookedData();
    }
  }, [dispatch, selectedCourt, selectedDate]);

  // all handles
  function handleDuration(duration) {
    setSelectedDuration(duration);
    setSelectedTimeSlots([]);
  }

  const handleAccordionToggle = (court) => {
    if (expandedCourt === court) {
      setExpandedCourt(null);
    } else {
      setExpandedCourt(court);
      setSelectedCourt(court);
    }
  };

  function handleBooking() {
    // Check if the user is logged in

    if (selectedTimeSlots.length === 0) {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        title: "Please Select Time Slots",
        confirmButtonText: "OK",
      });
      return;
    }

    // User is logged in, proceed with booking
    const bookingData = {
      totalAmount: calculateTotalPrice(),
      timeSlotsText: getTimeSlotText(),
      selectedCourtId: selectedCourt?._id,
      selectedTimeSlots: selectedTimeSlots,
      selectedDate: selectedDate,
      selectedDuration: selectedDuration,
      selectedGame : selectedGame
    };
    navigate("/checkout", { state: bookingData });
  }

  // helper functions
  const generateTimeSlots = (from, to) => {
    const timeSlots = [];
    const startTime = moment(from, "hh:mm A");
    const endTime = moment(to, "hh:mm A");

    while (startTime.isBefore(endTime)) {
      const formattedStartTime = startTime.format("hh:mm A");
      // Check if the current time slot falls within the excluded time ranges
      if (
        !(
          (startTime.isSameOrAfter(moment("02:30 AM", "hh:mm A")) &&
            startTime.isBefore(moment("06:00 AM", "hh:mm A"))) ||
          (startTime.isSameOrAfter(moment("09:30 AM", "hh:mm A")) &&
            startTime.isBefore(moment("04:00 PM", "hh:mm A")))
        )
      ) {
        timeSlots.push(formattedStartTime);
      }
      startTime.add(30, "minutes");
    }

    return timeSlots;
  };

  const getSevenDays = (selectedDays) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date();

    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);

      const formattedDate = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      const dayName = days[date.getDay()];
      const enabled = selectedDays.includes(dayName);

      return { day: dayName, date: formattedDate, enabled };
    });
  };

  const daysArray = useMemo(() => {
    return selectedCourt ? getSevenDays(selectedCourt.days) : [];
  }, [selectedCourt]);

  // validator
  const isTimeSlotSelected = (timeSlot) => {
    const trimmedTimeSlot = timeSlot.replace(/\s/g, "");
    return selectedTimeSlots[0] === trimmedTimeSlot;
  };

  const isTimeSlotBooked = (timeSlot) => {
    // Convert the time slot to the format used in the data
    const formattedTimeSlot = moment(timeSlot, "hh:mmA")
      .format("hh:mmA")
      .trim();

    return bookedTimeSlots?.some(
      (bookedSlot) =>
        bookedSlot?.time.replace(/\s/g, "") ===
        formattedTimeSlot.replace(/\s/g, "")
    );
  };

  const isPastTimeSlot = (timeSlot) => {
    if (!selectedDate) {
      return false;
    }

    const currentTime = moment();
    const selectedDateTime = moment(
      `${selectedDate} ${timeSlot}`,
      "DD/MM/YYYY hh:mm A"
    );

    return selectedDateTime.isBefore(currentTime);
  };

  const handleTimeSlotClick = async (index) => {
    try {
      const selectedStartTime = timeSlots[index].replace(/\s/g, "");
      const durationInMinutes = parseInt(selectedDuration);
      const slotsCount = Math.floor(durationInMinutes / 30);
      const selectedTimeSlotsCopy = [];

      // Generate time slots based on the duration
      for (let i = 0; i < slotsCount; i++) {
        const startTime = moment(selectedStartTime, "hh:mmA")
          .add(30 * i, "minutes")
          .format("hh:mmA");
        selectedTimeSlotsCopy.push(startTime);
      }

      // Dispatch the Redux action to check the availability of the selected slot
      const response = await dispatch(
        checkTimeSlotAvailability({
          courtId: selectedCourt._id,
          date: selectedDate,
          time: `${selectedStartTime}-${
            selectedTimeSlotsCopy[selectedTimeSlotsCopy.length - 1]
          }`,
        })
      );

      if (response.meta.requestStatus === "fulfilled") {
        setSelectedTimeSlots(selectedTimeSlotsCopy);
      } else if (response.meta.requestStatus === "rejected") {
        Swal.fire({
          icon: "error",
          title: `Error: ${response?.error?.message}`,
          allowOutsideClick: false,
        });
        setSelectedTimeSlots([]);
      }
    } catch (error) {
      console.log(error, "Unexpected error in handleTimeSlotClick");
    }
  };

  const handleDateClick = useCallback(
    (date) => {
      const clickedDate = daysArray.find((item) => item.date === date);
      if (clickedDate && clickedDate.enabled) {
        setselectedDate(date);
      }
      setSelectedTimeSlots([]);
    },
    [daysArray]
  );

  // calculations
  const getTimeSlotText = () => {
    if (selectedTimeSlots && selectedTimeSlots.length > 0) {
      const startTime = selectedTimeSlots[0];
      const endTime = moment(
        selectedTimeSlots[selectedTimeSlots.length - 1],
        "hh:mmA"
      )
        .add(30, "minutes")
        .format("hh:mmA");

      const slotConfirmed = `${startTime} to ${endTime}`;
      return slotConfirmed;
    } else {
      return "No TimeSlots Selected";
    }
  };

  const calculateTotalPrice = () => {
    const basePrice = price || 0;
    const totalPrice = basePrice * selectedTimeSlots.length;
    return totalPrice;
  };

  // slider & its effects
  useEffect(() => {
    // Use the slickGoTo method to set the initial slide to 1 for the main slider
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(1);
    }

    // Use the slickGoTo method to set the initial slide to 1 for the time slider
    if (timesliderRef.current) {
      timesliderRef.current.slickGoTo(0);
    }

    if (timeslotSectionRef.current) {
      timeslotSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [timeSlots]);

  var date_settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const duration_settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows : false,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
  };

  if (gameError?.status === 409) {
    Swal.fire({
      icon: "error",
      title: "Game Not Founds",
      text: "The requested game was not found.",
    }).then(() => {
      navigate("/");
    });
  }

  return (
    <>
      <Navbar />
      {/* Breadcrumb */}
      <Breadcrumb title={selectedGame} page={`Booking for ${selectedGame}`} />
      {/* /Breadcrumb */}
      <section className="booking-steps py-30">
        <div className="container">
          <ul className="d-xl-flex justify-content-center align-items-center">
            <li className="active">
              <h5>
                <a>
                  <span>1</span>Time &amp; Date
                </a>
              </h5>
            </li>

            <li>
              <h5>
                <a href="#" className="payment">
                  <span>2</span>Checkout
                </a>
              </h5>
            </li>
          </ul>
        </div>
      </section>
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <h4>
            <i className="fa-solid fa-clock me-2"></i>Select Duration :
          </h4>
          <div className="your-courts">
            <Slider {...duration_settings}>
              {durationOptions.map((duration, index) => (
                <button
                  key={index}
                  className={`select-court-badge${
                    selectedDuration === duration ? " selected" : ""
                  }`}
                  onClick={() => handleDuration(duration)}
                >
                   <span style={{ margin: isMobileView ? '15px' : '0' }}>{duration}</span> mins
                </button>
              ))}
            </Slider>
          </div>
          {/* <h4>
            <i className="fa-solid fa-table-columns me-2"></i>Select Court :
          </h4> */}

          <div className="row text-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="card time-date-card" ref={timeslotSectionRef}>
                <section className="booking-date">
                  <div className="list-unstyled  date-slider owl-theme mb-40">
                    <h5 className="text-start pb-3">
                      <i className="fa-solid fa-calendar-days me-2"></i>
                      Select Date :
                    </h5>
                    <Slider {...date_settings}>
                      {selectedCourt &&
                        daysArray
                          .filter((item) => item.enabled)
                          .map((item, index) => (
                            <div
                              className={`booking-date-item ${
                                selectedDate === item.date ? "selected" : ""
                              }`}
                              key={index}
                              onClick={() => handleDateClick(item.date)}
                            >
                              <h6>{item.day.substring(0, 3)}</h6>
                              <p>{item.date}</p>
                            </div>
                          ))}
                    </Slider>
                  </div>
                  <hr />
                  {/* select court - start */}
                  <h4 className="text-start ">
                    {/* <i className="fa-solid fa-calendar-days me-2"></i>
                    Select Court : */}
                  </h4>

                  <CourtAccordion
                    courts={courts}
                    expandedCourt={expandedCourt}
                    onAccordionToggle={handleAccordionToggle}
                    selectedDate={selectedDate}
                    handleTimeSlotClick={handleTimeSlotClick}
                    isTimeSlotBooked={isTimeSlotBooked}
                    isTimeSlotSelected={isTimeSlotSelected}
                    timeSlots={timeSlots}
                    isPastTimeSlot={isPastTimeSlot}
                  />

                  {/* select court - end */}

                  {/* here are the time slots - ends */}
                </section>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 desktop-bill">
              <aside className="card booking-details">
                <h3 className="border-bottom">Booking Details</h3>
                <ul>
                  <li>
                    <i className="fa-solid fa-calendar-days" />
                    {selectedDate ? selectedDate : "No Date Selected"}
                  </li>
                  <li>
                    <h6>Selected Time Slots :-</h6>
                  </li>
                  <li>
                    <div className="time-slot-main-div">
                      {getTimeSlotText()}
                    </div>
                  </li>
                  <li>
                    <i className="fa-solid fa-clock"></i>
                    {selectedDuration} mins
                  </li>
                </ul>
                <div className="d-grid btn-block">
                  <button
                    type="button"
                    className="btn btn-custom"
                    onClick={handleBooking}
                  >
                    Book Now : {calculateTotalPrice()}/-
                  </button>
                </div>
              </aside>
            </div>

            {/* mobile responsive booking-details-start */}
            <div className="mobile-bill px-3 ">
              <div>
                Selected Time Slots : {getTimeSlotText()}
                {/* <span>Total : {calculateTotalPrice()}/- </span> */}
              </div>
              <button className="btn btn-custom" onClick={handleBooking}>
                Proceed
              </button>
            </div>
            {/* mobile responsive booking-details-end  */}
          </div>
        </div>
        {/* /Container */}
      </div>
      {/* /Page Content */}

      {!isMobileView && <Footer />}
    </>
  );
};

export default Booking;

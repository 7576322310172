import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Breadcrumb from "../Common/Breadcrumb";

const TermsCond = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={`Terms & Conditions`} page={`Terms & Conditions`} />
      <div class="content">
        <div class="container">
          <p>
          Welcome to ProPadel, your premier destination for booking pickle and paddle court reservations. These Terms and Conditions ("Terms") govern your use of the ProPadel mobile application ("App") and website ("Website") operated by ProPadel LLC ("Pro Padel," "we," "us," or "our"). By accessing or using the ProPadel App or Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our services.
          </p>
          
          <ul className="policy">
            <strong>1. Account Registration</strong>
            <li>Eligibility: You must be at least 13 years old to create an account with ProPadel. By creating an account, you represent that you are of legal age to form a binding contract with ProPadel.</li>
            <li>Accuracy: You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated.</li>
              <li>Security: You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account. You agree to notify us immediately of any unauthorized access to or use of your account.</li>
          </ul>

          <ul className="policy">
            <strong>2. Booking and Payments</strong>
            <li>Court Reservations: ProPadel provides a platform for users to book pickle and paddle court reservations at participating facilities. All bookings are subject to availability and the terms and conditions of the respective facilities.</li>
            <li>Payment: You agree to pay all fees associated with your bookings, including court rental fees, service fees, and any applicable taxes. Payments are processed securely through our payment gateway.</li>
            <li>Cancellation and Refunds: Cancellation policies vary by facility. Please review the cancellation policy provided during the booking process. Refunds, if applicable, will be issued in accordance with the facility's cancellation policy.</li>
          </ul>

          <ul className="policy">
            <strong>3. User Conduct</strong>
            <li>Compliance: You agree to use the ProPadel services in compliance with these Terms, applicable laws, and regulations.</li>
            <li>Prohibited Activities: You may not engage in any activity that could harm, disable, overburden, or impair the ProPadel services or interfere with other users' enjoyment of the services.</li>
            <li>Content Guidelines: You are solely responsible for any content you submit or transmit through the ProPadel platform. You agree not to post or transmit any content that is unlawful, offensive, or infringes upon the rights of others.</li>
          </ul>
         
         <ul className="policy">
          <strong>4. Intellectual Property</strong>
          <li>Ownership: The ProPadel App, Website, and all content and materials provided therein are owned or licensed by ProPadel and are protected by copyright, trademark, and other intellectual property laws.</li>
          <li>License: Subject to these Terms, ProPadel grants you a limited, non-exclusive, revocable license to access and use the ProPadel services for personal, non-commercial purposes.</li>
         </ul>

         <ul className="policy">
          <strong>5. Disclaimer of Warranties</strong>
          <li>As-Is Basis: The Pro Padel services are provided on an "as-is" and "as available" basis, without any warranties of any kind, either express or implied.</li>
          <li>Use at Your Own Risk: We do not warrant that the Pro Padel services will be uninterrupted, error-free, or free of viruses or other harmful components. You use the services at your own risk.</li>
         </ul>

         <ul className="policy">
          <strong>6. Changes to these Terms</strong>
          <li>
          We reserve the right to update or modify these Terms at any time without prior notice. Any changes to these Terms will be effective upon posting the revised Terms on the Pro Padel Website or App. Your continued use of the services after the changes become effective constitutes your acceptance of the revised Terms.
          </li>
         </ul>

         <ul className="policy pb-5">
          <strong>7. Contact Us</strong>
          <li>If you have any questions, concerns, or feedback about these Terms, please contact us at propadelahmedabad@gmail.com.</li>
         </ul>

         <strong>Thank you for choosing Pro Padel. We hope you enjoy your experience with us!</strong>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCond;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingTable from "./BookingTable";
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Common/Breadcrumb";
import ProfileTabs from "./ProfileTabs";
import Footer from "../Footer/Footer";
import { fetchBookingDetails } from "../../Redux/Slices/profileSlice";

const MyBookings = () => {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.bookings);
  
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchBookingDetails());
    }
  }, [status, dispatch]);
  
  const sortedData = [...data].sort((a, b) => b.date.localeCompare(a.date));

  const columns = [
    { Header: "Order ID", accessor: "orderId" },
    { Header: "User Name", accessor: "userName" },
    { Header: "Game Name", accessor: "gameDetails.gameName" },
    { Header: "Court No", accessor: "courtDetails.courtNo" },
    { Header: "Price", accessor: "price" },
    { Header: "Date", accessor: "date" },
    {
      Header: "Booking Type",
      accessor: "onlineBooking",
      Cell: ({ value }) => (
        <span className={`badge bg-${value ? "primary" : "secondary"}`}>
          {value ? "Online" : "Offline"}
        </span>
      ),
    },
    {
      Header : "Payment Status",
      accessor : "paymentStatus",
      Cell: ({ value }) =>  value ? value : "-",
    },
    {
      Header : "Transaction ID",
      accessor: "transactionId",
      Cell: ({ value }) => value ? value : "-",
    },
    { Header: "Time Duration", accessor: "timeDuration" },
  ];

  return (
    <>
      <Navbar />
      <Breadcrumb title={"My Bookings"} page={"My Bookings"} />
      <ProfileTabs />
      <div className="content court-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="court-tab-content">
                <div className="card card-tableset">
                  <div className="card-body">
                    <div className="coache-head-blk">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="court-table-head">
                            <h4>My Bookings</h4>
                            <p>Manage and track all your court bookings.</p>
                          </div>
                        </div>
                        <div className="col-md-7"></div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="nav-Recent"
                        role="tabpanel"
                        aria-labelledby="nav-Recent-tab"
                        tabIndex={0}
                      >
                        <div className="table-responsive table-datatble">
                          {status === "loading" && <span>Loading .....</span>}

                          {status === "failed" && <span>{error}</span>}

                          {status === "succeeded" && (
                            <BookingTable columns={columns} data={sortedData} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyBookings;

import React from "react";
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Common/Breadcrumb";
import Footer from "../Footer/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={`Privacy Policy`} page={`Privacy Policy`} />
      <div class="content">
        <div class="container">
          <h3>Effective Date: February 15, 2024</h3>
          <p>
            Welcome to ProPadel, your premier destination for booking pickle and
            paddle court reservations. At ProPadel, we value your privacy and
            are committed to protecting your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and protect your
            information when you use our mobile application ("App") or visit our
            website ("Website").
          </p>
          <p>
            By using the ProPadel App or Website, you consent to the collection
            and use of your information in accordance with this Privacy Policy.
            If you do not agree with any part of this Privacy Policy, please do
            not use our services.
          </p>
          <ul className="policy">
            <strong>1. Information We Collect</strong>
            <li>
              Personal Information: When you create an account with ProPadel, we
              may collect personal information such as your name, email address,
              phone number, and billing information.
            </li>
            <li>
              Usage Information: We collect information about how you use our
              App and Website, including your preferences, session data, and
              interactions with our services.
            </li>
            <li>
              Location Information: With your consent, we may collect precise
              location information from your device when you use our
              location-based services.
            </li>
            <li>
              Communications: We may collect information you provide when you
              contact our customer support team or communicate with us through
              email or other channels.
            </li>
          </ul>
          <ul className="policy">
            <strong>2. How We Use Your Information</strong>
            <li>
              To Provide Services: We use your information to provide you with
              access to our booking services, process transactions, and manage
              your account.
            </li>
            <li>
              Personalization: We may personalize your experience by providing
              tailored content, recommendations, and offers based on your
              preferences and usage patterns.
            </li>
            <li>
              {" "}
              Improvement: Your feedback and usage data help us improve our
              services, identify technical issues, and develop new features.
            </li>
            <li>
              Communication: We may send you important updates, notifications,
              and promotional materials related to our services. You can opt-out
              of marketing communications at any time.
            </li>
          </ul>
          <ul className="policy">
            <strong>How We Share Your Information</strong>
            <li>
              Service Providers: We may share your information with third-party
              service providers who assist us in operating our business,
              processing payments, or delivering services to you.
            </li>
            <li>
              Legal Compliance: We may disclose your information in response to
              legal requests, court orders, or to comply with applicable laws
              and regulations.
            </li>
            <li>
              Business Transfers: In the event of a merger, acquisition, or sale
              of all or a portion of our assets, your information may be
              transferred as part of the transaction.
            </li>
          </ul>
          <ul className="policy">
            <strong>4. Data Security</strong>
            <li>
              We take reasonable measures to protect your information from
              unauthorized access, alteration, disclosure, or destruction.
              However, no method of transmission over the internet or electronic
              storage is 100% secure, and we cannot guarantee absolute security.
            </li>
          </ul>
          <ul className="policy">
            <strong>5. Your Choices</strong>
            <li>
              Account Information: You can review and update your account
              information at any time by logging into your Pro Padel account.
            </li>
            <li>
              Location Services: You can control location sharing preferences
              through your device settings or by adjusting permissions within
              our App.
            </li>
            <li>
              Opt-Out: You can opt-out of receiving marketing communications by
              following the unsubscribe instructions provided in the messages or
              by contacting us directly.
            </li>
          </ul>
          <ul className="policy">
            <strong>6. Children's Privacy</strong>
            <li>
              Our services are not directed to individuals under the age of 13,
              and we do not knowingly collect personal information from
              children. If you believe that we have inadvertently collected
              information from a child, please contact us immediately.
            </li>
          </ul>
          <ul className="policy">
            <strong>7. Changes to this Privacy Policy</strong>
            <li>
              We reserve the right to update or modify this Privacy Policy at
              any time. We will notify you of any material changes by posting
              the revised policy on our Website or through other communication
              channels. Your continued use of our services after the changes
              become effective constitutes your acceptance of the revised
              policy.
            </li>
          </ul>
          <ul className="policy pb-5">
            <strong>8. Contact Us</strong>
            <li>
              If you have any questions, concerns, or feedback about this
              Privacy Policy or our privacy practices, please contact us at
              propadelahmedabad@gmail.com
            </li>
          </ul>
          <strong>
            Thank you for choosing ProPadel. We appreciate your trust in us to
            handle your information responsibly.
          </strong>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;

import React, { useEffect, useMemo, useState } from "react";
import logo from "../../assets/img/logo.png";
import {  useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAsync } from "../../Redux/Slices/authSlice";
import Swal from "sweetalert2";

const ResetPwd = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [token, setToken] = useState();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  
  useEffect(() => {
    let token = query.get("token");
    setToken(token);
  }, [query]);

  const resetPassword = async (e) => {
    e.preventDefault();

    // Ensure both password fields are filled
    if (!formData.password || !formData.confirmPassword) {
      console.log("Password and Confirm Password are required");
      return;
    }

    // Ensure passwords match
    if (formData.password !== formData.confirmPassword) {
      console.log("Passwords do not match");
      return;
    }

    try {
      // Dispatch the async thunk
      const response = await dispatch(
        resetPasswordAsync({
          token,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
        })
      );

      if (response?.error) {
        Swal.fire({
          icon: "error",
          title: "Error Changing Password",
        });
      } else if (response?.payload) {
        Swal.fire({
          icon: "success",
          title: `${response?.payload?.message}`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Changing Password",
      });
    }
  };
  return (
    <div className="main-wrapper authendication-pages">
      <div className="content">
        <div className="container wrapper no-padding">
          <div className="row no-margin vph-100">
            <div className="col-12 col-sm-12 col-lg-6 no-padding">
              <div className="banner-bg login">
                <div className="row no-margin h-100"></div>
              </div>
            </div>
            <div className="col-12 col-sm-12  col-lg-6 no-padding">
              <div className="dull-pg">
                <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <header className="text-center">
                      <a href="user-dashboard.html">
                        <img src={logo} className="img-fluid w-25" alt="Logo" />
                      </a>
                    </header>
                    <div className="shadow-card">
                      <h2>Reset Password</h2>
                      <p></p>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="user-tab"
                        >
                          {/* Login Form */}
                          <form onSubmit={resetPassword}>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-user" />
                                <input
                                  type="text"
                                  name="password"
                                  className="form-control"
                                  placeholder="Enter New Password"
                                  value={formData.password}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      password: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="pass-group group-img">
                                <i className="toggle-password feather-eye-off" />
                                <input
                                  type="password"
                                  name="confirmPassword"
                                  className="form-control pass-input"
                                  placeholder="Confirm New Password"
                                  value={formData.confirmPassword}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      confirmPassword: e.target.value,
                                    })
                                  }
                                />
                                {formData.password !==
                                  formData.confirmPassword && (
                                  <div className="error-message px-2 pt-3">
                                    <span className="text-danger">
                                      {" "}
                                      Passwords do not match{" "}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>

                            <button
                              className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                              type="submit"
                            >
                              {loading === "pending" ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Reset Password"
                              )}
                              <i className="feather-arrow-right-circle ms-2" />
                            </button>
                          </form>
                          {/* /Login Form */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coach"
                          role="tabpanel"
                          aria-labelledby="coach-tab"
                        ></div>
                      </div>
                    </div>
                    <div className="bottom-text text-center">
                      {/* <p>
                        Don’t have an account?{" "}
                        <Link to="/register">Sign up!</Link>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPwd;

// courtTimeSlotSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the initial state
const initialState = {
  courtTimeSlots: [],
  status: "idle",
  error: null,
};

// Create an async thunk for fetching court time slots
export const fetchCourtTimeSlots = createAsyncThunk(
  "courtTimeSlot/fetchCourtTimeSlots",
  async ({ courtId, date }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/getCourtTimeSlot?courtId=${courtId}&date=${date}`
    );
    return response.data;
  }
);

export const checkTimeSlotAvailability = createAsyncThunk(
  "courtTimeSlot/checkTimeSlotAvailability",
  async ({courtId, date, time}) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/checkDuration?courtId=${courtId}&date=${date}&time=${time}`
      );
      return response.data
      // console.log("Response:", response.data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const courtTimeSlotSlice = createSlice({
  name: "courtTimeSlot",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourtTimeSlots.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCourtTimeSlots.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(fetchCourtTimeSlots.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(checkTimeSlotAvailability.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkTimeSlotAvailability.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courtTimeSlots = action.payload;
      })
      .addCase(checkTimeSlotAvailability.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default courtTimeSlotSlice.reducer;

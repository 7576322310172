import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Breadcrumb from "../Common/Breadcrumb";
import ProfileTabs from "./ProfileTabs";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../../Redux/Slices/profileSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const { userDetails, isLoading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Breadcrumb title={"Profile"} page={"Profile"} />
      <div>
        {/* Dashboard Menu */}
        <ProfileTabs />
        {/* /Dashboard Menu */}
        {/* Page Content */}
        <div className="content court-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="court-tab-content">
                  <div className="card card-tableset">
                    <div className="card-body">
                      <div className="coache-head-blk">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="court-table-head">
                              <h4>My Profile</h4>
                              <div className="profile-details">
                                {isLoading ? (
                                  <div
                                    class="spinner-border text-primary"
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <>
                                    <p>
                                      <strong>Name : </strong>
                                      {userDetails?.fullName}
                                    </p>
                                    <p>
                                      <strong>Email : </strong>
                                      {userDetails?.email}
                                    </p>
                                    <p>
                                      <strong>Phone No : </strong>
                                      {userDetails?.phoneNumber}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>

      <Footer />
    </>
  );
};

export default Profile;

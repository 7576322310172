import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./contact.css";
import Breadcrumb from "../Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { sendContactForm } from "../../Redux/Slices/contactSlice";
import Swal from "sweetalert2";

const Contact = () => {
  const dispatch = useDispatch();
  const contactStatus = useSelector((state) => state.contact.status);
  // const contactError = useSelector((state) => state.contact.error);
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    phoneNo: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    subject: "",
    comment: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let updatedValue = value;
    let updatedErrors = { ...validationErrors };

    if (id === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (value !== "" && !emailRegex.test(value)) {
        updatedErrors[id] = "Invalid email address";
      } else {
        delete updatedErrors[id];
      }
    } else if (id === "phoneNo") {
      if (value.length !== 10) {
        updatedErrors[id] = "Invalid phone number";
      } else {
        delete updatedErrors[id];
      }
    } else {
      // Clear the error if it exists when other fields change
      delete updatedErrors[id];
    }

    setValidationErrors(updatedErrors);
    setFormData({ ...formData, [id]: updatedValue });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(sendContactForm(formData));
    if (response?.error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `Failed to submit form.`,
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your form has been submitted successfully.",
      }).then(() => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNo: "",
          subject: "",
          comment: "",
        });
      });
    }
  };
  return (
    <>
      <Navbar />
      {/* Breadcrumb */}
      <Breadcrumb title="Contact Us" page="Contact US" />
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content blog-details contact-group">
        <div className="container ">
          <h2 className="text-center mb-40">Contact Information</h2>
          <div className="row mb-40">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 ">
              <div className="d-flex justify-content-start align-items-center details contact-icon">
                <i className="fa-solid fa-envelope"></i>
                <div className="info">
                  <h4>Email Address</h4>
                  <a href="mailto:info@example.com">propadelahmedabad@gmail.com</a>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="d-flex justify-content-start align-items-center details contact-icon">
                <i className="fa-solid fa-phone"></i>
                <div className="info">
                  <h4>Phone Number</h4>
                  <span>+91 9510496090</span>
                 <span>+91 8849788752</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
              <div className="d-flex justify-content-start align-items-center details contact-icon">
                <i className="fa-solid fa-location-dot "></i>
                <div className="info">
                  <h4>Location</h4>
                  <span>Sindhubhavan Extention road, Divya Jyot School Rd, Shilaj, Gujarat 380058</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="google-maps">
                <iframe
                  title="Google Maps"
                  height={445}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117489.36287581654!2d72.43792169570389!3d23.0405611589362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bf6d98d4b9d%3A0xfb4d6f035a9104f8!2sPro%20Padel%20%26%20Pickleball%20Ahmedabad!5e0!3m2!1sen!2sin!4v1704953250041!5m2!1sen!2sin"
                  style={{ border: 0, width: "100%", borderRadius: "12px" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
        <section className="section dull-bg">
          <div className="container contact-form-start ">
            <h2 className="text-center mb-40">
              Reach out to us and let's smash your inquiries
            </h2>
            <form className="contact-us" onSubmit={handleFormSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="first-name" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="Enter First Name"
                    required
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="last-name" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Enter Last Name"
                    required
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="e-mail" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter Email Address"
                    required
                  />
                  {validationErrors.email && (
                    <div className="text-danger mt-2">
                      {validationErrors.email}
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-6 mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleInputChange}
                    placeholder="Enter Phone Number"
                    required
                  />
                  {validationErrors.phoneNo && (
                    <div className="text-danger mt-2">
                      {validationErrors.phoneNo}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    placeholder="Enter Subject"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="comments" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control"
                  id="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="Enter Comments"
                  defaultValue={""}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-secondary d-flex align-items-center  mt-4 "
                disabled={contactStatus === "loading"}
              >
                {contactStatus === "loading" ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </section>
      </div>
      {/* /Page Content */}
      <Footer />
    </>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Breadcrumb from "../Common/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import rackets from "../../assets/game/rackets.png";
import bottle from "../../assets/game/bottle.png";
import toilet from "../../assets/game/toilet.png";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const GameDetails = () => {
  const { game, id } = useParams();
  const { token } = useSelector(state => state.auth);
  const location = useLocation(); 
  const navigate = useNavigate();
  const [gamePrice, setgamePrice] = useState()
  
  useEffect(() => {
   if (game === "Pickle Ball") {
    setgamePrice("800")
   }else if (game === "Padel Tennis") {
    setgamePrice("1500")
   }else {
    setgamePrice("500")
   }
  }, [game]);


  function handleNavigate() {
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Login Required",
        allowEscapeKey: false,
        text: "Please login to proceed further.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/auth/login", { state: { redirectPath: location.pathname } });
      });
      return;
    }

    navigate(`/booking/${game}/${id}`);
  }

  function handleContact(){
    navigate(`/contact`)
  }

  function handleDirections() {
    // Create the Google Maps URL
    const mapsUrl = `https://maps.app.goo.gl/yShqkPXbDz49b6XX8`;
    
    // Open the Google Maps URL in a new tab/window
    window.open(mapsUrl, '_blank');
  }
  

  return (
    <>
      <Navbar />
      <Breadcrumb title={game} page={game} />
      {/* Page Content */}
      <div className="content">
        <div className="container">
          {/* Row */}
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Facilities :</h5>
                  <p class="card-text">
                    <div className="facilites mt-4">
                      <div>
                        <img src={rackets} alt="rackets" />
                        <span className="pt-3">Padels</span>
                      </div>
                      <div>
                        <img src={bottle} alt="bottle" />
                        <span className="pt-3">Water</span>
                      </div>
                      <div>
                        <img src={toilet} alt="toilets" />
                        <span className="pt-3">Toilets</span>
                      </div>
                    </div>
                    <hr />
                  </p>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <h5 class="card-title">Opening times:</h5>
                      <p class="card-text mb-0">06:00 AM - 10:00 AM</p>
                      <p class="card-text mt-0 ">04:00 PM - 03:00 AM</p>
                    </div>
                    <div>
                      <button className="btn btn-primary" onClick={handleContact}>Contact us</button>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between ">
                    <div>
                      <h5 class="card-title">Location:</h5>
                      <p class="card-text">
                        Sindhubhavan Extention road, Divya Jyot School Rd,
                        Shilaj, Gujarat 380058
                      </p>
                    </div>
                    <div>
                      <button className="btn btn-primary" onClick={handleDirections}>Directions</button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Accordian Contents */}
            </div>
            <aside className="col-12 col-sm-12 col-md-12 col-lg-4 theiaStickySidebar">
              <div className="white-bg book-court">
                <strong className="text-center">
                  Starting from {gamePrice}/- per hour
                </strong>
                <div className="d-grid btn-block mt-3">
                  <a
                    className="btn btn-primary d-inline-flex justify-content-center align-items-center"
                    onClick={handleNavigate}
                  >
                    <i className="feather-calendar" />
                    Book Now
                  </a>
                </div>
              </div>
            </aside>
          </div>
          {/* /Row */}
        </div>
      </div>
      {/* /Page Content */}
      <Footer />
    </>
  );
};

export default GameDetails;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Breadcrumb from "../Common/Breadcrumb";
import padel from "../../assets/rules/padelrules.jpg";
import pickle from "../../assets/rules/pickleballrules.jpg";
import { Link } from "react-router-dom";
import padeltennis from "../../assets/rules/pr.pdf";
import pickleball from "../../assets/rules/pb.pdf";

const Rule = () => {

  const handleDownload = (pdfPath) => {
    // Create a link element
    const link = document.createElement("a");
    link.href = pdfPath; // Set the href attribute to the PDF file path
    link.download = "GameRules.pdf"; // Set the desired file name for download
    link.target = "_blank";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document after the download
    document.body.removeChild(link);
  };
  
  return (
    <>
      <Navbar />
      <Breadcrumb title="Game Rules" page="Game Rules" />
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-lg-6">
            <div className="featured-venues-item  aos" data-aos="fade-up">
              <div className="listing-item mb-0">
                <div className="listing-img">
                  <a>
                    <img src={padel} alt="Venue" />
                  </a>
                </div>
                <div className="listing-content">
                  <div className="booking-div">
                    <h4 className="listing-title">
                      <Link>Padel Tennis</Link>
                    </h4>
                    <button
                      className="user-book-now"
                      onClick={() => handleDownload(padeltennis)}
                    >
                      <span>
                        <i className="feather-calendar me-2" />
                      </span>
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="featured-venues-item  aos" data-aos="fade-up">
              <div className="listing-item mb-0">
                <div className="listing-img">
                  <a>
                    <img src={pickle} alt="Venue" />
                  </a>
                </div>
                <div className="listing-content">
                  <div className="booking-div">
                    <h4 className="listing-title">
                      <Link>Pickle Ball</Link>
                    </h4>

                    <button
                      className="user-book-now"
                      onClick={() => handleDownload(pickleball)}
                    >
                      <span>
                        <i className="feather-calendar me-2" />
                      </span>
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rule;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import calendar from "../../assets/how-it-works/calendar.png";
import court from "../../assets/how-it-works/court.png";
import secured from "../../assets/how-it-works/encrypted.png";
import game from "../../assets/how-it-works/tennis-ball.png";
import bookicon from "../../assets/keyfeatures/booking.png";
import comfortable from "../../assets/keyfeatures/convenient.png";
import courticon from "../../assets/keyfeatures/court.png";
import atmosphere from "../../assets/keyfeatures/good-atmosphere.png";
import support from "../../assets/keyfeatures/support.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllGames } from "../../Redux/Slices/gameSlice";
import Swal from "sweetalert2";
import { testimonialData } from "./testimonialData";
import courtImg from "../../assets/img/court.jpg"

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const games = useSelector((state) => state?.allGames?.games);
  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    const justLoggedIn =
      token && !localStorage.getItem("welcomeAlertShown"); 

    if (justLoggedIn) {
      Swal.fire({
        icon: "success",
        title: "Welcome to Pro Padel ",
        imageWidth: 300,
        imageHeight: 200,
        imageAlt: "Custom image",
      });
      localStorage.setItem("welcomeAlertShown", "true");
    }
  }, [token]);

  useEffect(() => {
    dispatch(getAllGames());
  }, [dispatch]);


  function handleGameCourt(id, gameName) {
    navigate(`/game/${gameName}/${id}`);
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const BannerSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <section className="slider-container">
        <Slider {...BannerSettings}>
          <div className="hero-section hero-section-1">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center w-100">
                  <div className="col-lg-12 col-md-12 mx-auto">
                    <div className="section-search aos" data-aos="fade-up">
                      <h1>Pro Padel Ahmedabad</h1>
                      <p className="sub-info">
                        Ultimate Padel and Pickle Ball Experience
                      </p>
                      <a className="btn btn-primary mt-3" href="#games">
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-section hero-section-2">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center w-100">
                  <div className="col-lg-12 col-md-12 mx-auto">
                    <div className="section-search aos" data-aos="fade-up">
                      <h1>
                        Book Your Padel & Pickleball Courts at Propadel
                        Ahmedabad Today !
                      </h1>
                      <p className="sub-info">
                        Unleash Your Athletic Potential....
                      </p>
                      <a className="btn btn-primary mt-3" href="#games">
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-section hero-section-3">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center w-100">
                  <div className="col-lg-12 col-md-12 mx-auto">
                    <div className="section-search aos" data-aos="fade-up">
                      <h1>Take Your Padel Skills to the Next Level</h1>
                      <a className="btn btn-primary mt-3" href="#games">
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      {/* /Hero Section */}

      {/* How It Works */}
      <section className="section work-section">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              How It <span>Works</span>
            </h2>
            <p className="sub-title">
              Simplifying the booking process for coaches, venues, and athletes.
            </p>
          </div>
          <div className="row justify-content-center stepper-line">
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <img src={game} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <a href="register.html">1. Select Your Preferred Game</a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <img src={calendar} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <a href="coaches-list.html">
                      2. Select Your Date & Time Slot
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <img src={court} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <a href="coach-details.html">
                      3. Choose Your Preferred Court
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="work-grid w-100 aos" data-aos="fade-up">
                <div className="work-icon">
                  <div className="work-icon-inner">
                    <img src={secured} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h5>
                    <a href="coach-details.html">4.Secured Payment Gateway</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /How It Works */}

      {/* Games */}
      <section className="section featured-venues" id="games">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Featured <span>Games</span>
            </h2>
            <p className="sub-title">
              Our Advanced courts offer the latest facilities, dynamic and
              unique environments for enhanced performance.
            </p>
          </div>
          <div className="row">
            {games
              ? games.map((game) => (
                  <div className="col-lg-6" key={game._id}>
                    <div
                      className="featured-venues-item  aos"
                      data-aos="fade-up"
                    >
                      <div className="listing-item mt-3 mb-0">
                        <div className="listing-img">
                          <a>
                            <img src={courtImg} alt="Venue" />
                          </a>
                          {/* <div className="fav-item-venues">
                            <span className="tag tag-blue">Featured</span>
                            <h5 className="tag tag-primary">
                              800<span>/hr</span>
                            </h5>
                          </div> */}
                        </div>
                        <div className="listing-content">
                          <div className="booking-div">
                            <h4 className="listing-title">
                              <Link to="/booking/Padel Tennis">
                                {game?.game_Name}
                              </Link>
                            </h4>

                            <button
                              onClick={() =>
                                handleGameCourt(game._id, game.game_Name)
                              }
                              className="btn btn-primary"
                            >
                              <span>
                                <i className="feather-calendar me-2" />
                              </span>
                              Book Now
                            </button>
                          </div>
                          <h6>
                            Total Courts Available : {game?.courts?.length}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
      {/* /Games */}

      {/* Convenient */}
      {/* <section className="section convenient-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mycounter">
              <i class="fa-solid fa-users"></i>
              <span>1226</span>
              <span>PARTICIPANT</span>
            </div>
            <div className="col-lg-3 mycounter">
              <i class="fa-solid fa-location-dot"></i>
              <span>255</span>
              <span>TOTAL EVENTS</span>
            </div>
            <div className="col-lg-3 mycounter">
              <i class="fa-solid fa-globe"></i>
              <span>09</span>
              <span>VENUES</span>
            </div>
            <div className="col-lg-3 mycounter">
              <i class="fa-solid fa-money-bill"></i>
              <span>19</span>
              <span>SPONSORS</span>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Convenient */}

      {/* Group Coaching */}
      <section className="section group-coaching ">
        <div className="container">
          <div className="section-heading key-features  aos" data-aos="fade-up">
            <h2>
              Key Features of <span>Pro Padel & Pickleball Ahmedabad</span>
            </h2>
            <p className="sub-title">
              Discover your potential with our comprehensive training, expert
              trainers, and advanced facilities. Join us to improve your
              athletic career.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="key-work-icon">
                  <div className="work-icon-inner key-img">
                    <img src={courticon} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Top Quality Courts</h3>
                  <p>
                    At Pro padel we have modern and well maintained courts with
                    great attention to lighting .
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="key-work-icon">
                  <div className="work-icon-inner  key-img">
                    <img src={atmosphere} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Welcoming Atmosphere</h3>
                  <p>
                    We organize activities,competition,trainings and events.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="key-work-icon">
                  <div className="work-icon-inner  key-img">
                    <img src={support} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h3>We are For Everyone</h3>
                  <p>
                    Racket sports is fun and easy to play for all age groups &
                    members.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="key-work-icon">
                  <div className="work-icon-inner  key-img">
                    <img src={comfortable} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Comfortable and Affordable</h3>
                  <p>
                    Hourly booking options with competitive prices. Our
                    facilities include a lounge area, cafe and a spacious
                    parking area.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div
                className="work-grid coaching-grid w-100 aos"
                data-aos="fade-up"
              >
                <div className="key-work-icon">
                  <div className="work-icon-inner  key-img">
                    <img src={bookicon} alt="Icon" />
                  </div>
                </div>
                <div className="work-content">
                  <h3>Flexible and Quick Booking Process</h3>
                  <p>
                    Bookings can be made seamlessly through our online portal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Group Coaching */}

      {/* Best Services */}
      {/* FAq here */}
      {/* /Best Services */}

      {/* Testimonials */}
      <section className="section our-testimonials">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Client <span>Testimonials</span>
            </h2>
          </div>
          <div className="row">
            <div className="featured-slider-group aos" data-aos="fade-up">
              <Slider {...settings} className="home-slick-slider">
                {testimonialData?.map((testimonial, index) => (
                  <div key={index} className="testimonial-group p-2">
                    <div className="testimonial-review">
                      <div className="rating-point">
                        {Array.from({ length: 5 }, (_, i) => (
                          <i key={i} className="fas fa-star filled" />
                        ))}
                      </div>
                      <p>{testimonial.review}</p>
                    </div>
                    <div className="listing-venue-owner">
                      <a className="navigation">
                        <i
                          className="fa-solid fa-user"
                          style={{ fontSize: "24px" }}
                        ></i>
                      </a>
                      <div className="testimonial-content">
                        <h5>
                          <a>{testimonial.name}</a>
                        </h5>
                        {/* <a className="btn btn-primary ">{testimonial.sport}</a> */}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Testimonials */}
      {/* Featured Plans */}
      {/* <section className="section featured-plan">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              We Have Excellent <span>Plans For You</span>
            </h2>
            <p className="sub-title">
              Choose monthly or yearly plans for uninterrupted access to our
              premium badminton facilities. Join us and experience convenient
              excellence.
            </p>
          </div>
          <div className="interset-btn aos" data-aos="fade-up">
            <div className="status-toggle d-inline-flex align-items-center">
              Pickle Ball
              <input
                type="checkbox"
                id="status_1"
                className="check"
                checked={isGameChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="status_1" className="checktoggle">
                checkbox
              </label>
              Paddle Tennis
            </div>
          </div>
          <div className="price-wrap aos" data-aos="fade-up">
            <div className="row justify-content-center">
              {isGameChecked ? (
                <>
                  <div className="col-lg-6 d-flex col-md-6">
            
                    <div className="price-card flex-fill ">
                      <div className="price-head">
                        <img src={price1} alt="Price" />
                        <h3>Professoinal</h3>
                      </div>
                      <div className="price-body">
                        <div className="per-month">
                          <h2>
                            <sup>$</sup>
                            <span>60.00</span>
                          </h2>
                          <span>Per Month</span>
                        </div>
                        <div className="features-price-list">
                          <h5>Features</h5>
                          <p>Everything in our free Upto 10 users. </p>
                          <ul>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Quality Checked By Envato
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Future Updates
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Technical Support
                            </li>
                            <li className="inactive">
                              <i className="feather-x-circle" />
                              Add Listing
                            </li>
                            <li className="inactive">
                              <i className="feather-x-circle" />
                              Approval of Listing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
              
                  </div>
                  <div className="col-lg-6 d-flex col-md-6">
                   
                    <div className="price-card flex-fill">
                      <div className="price-head expert-price">
                        <img src={price2} alt="Price" />
                        <h3>Expert</h3>
                        <span>Recommended</span>
                      </div>
                      <div className="price-body">
                        <div className="per-month">
                          <h2>
                            <sup>$</sup>
                            <span>60.00</span>
                          </h2>
                          <span>Per Month</span>
                        </div>
                        <div className="features-price-list">
                          <h5>Features</h5>
                          <p>Everything in our free Upto 10 users. </p>
                          <ul>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Quality Checked By Envato
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Future Updates
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />6 Months
                              Technical Support
                            </li>
                            <li className="inactive">
                              <i className="feather-x-circle" />
                              Add Listing
                            </li>
                            <li className="inactive">
                              <i className="feather-x-circle" />
                              Approval of Listing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 d-flex col-md-6">
                  
                    <div className="price-card flex-fill">
                      <div className="price-head">
                        <img src={price3} alt="Price" />
                        <h3>Enterprise</h3>
                      </div>
                      <div className="price-body">
                        <div className="per-month">
                          <h2>
                            <sup>$</sup>
                            <span>990.00</span>
                          </h2>
                          <span>Per Month</span>
                        </div>
                        <div className="features-price-list">
                          <h5>Features</h5>
                          <p>Everything in our free Upto 10 users. </p>
                          <ul>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Quality Checked By Envato
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Future Updates
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Technical Support
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Add Listing
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Approval of Listing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className="col-lg-6 d-flex col-md-6">
                    
                    <div className="price-card flex-fill">
                      <div className="price-head">
                        <img src={price3} alt="Price" />
                        <h3>beginner</h3>
                      </div>
                      <div className="price-body">
                        <div className="per-month">
                          <h2>
                            <sup>$</sup>
                            <span>990.00</span>
                          </h2>
                          <span>Per Month</span>
                        </div>
                        <div className="features-price-list">
                          <h5>Features</h5>
                          <p>Everything in our free Upto 10 users. </p>
                          <ul>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Quality Checked By Envato
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Included : Future Updates
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Technical Support
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Add Listing
                            </li>
                            <li className="active">
                              <i className="feather-check-circle" />
                              Approval of Listing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section> */}
      {/* /Featured Plans */}

      <Footer />
    </>
  );
};

export default Home;

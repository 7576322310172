import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../Redux/Slices/authSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [passwordError, setpasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPasswordStrengthBar, setShowPasswordStrengthBar] = useState(false);

  const calculatePasswordStrength = (password) => {
    return Math.min(password.length / 8, 1); // A simple example, assuming a good password has at least 8 characters
  };

  const handleXMark = () => {
    navigate("/");
  };

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate phone number input
    if (name === "phoneNumber" && value.length !== 10) {
      setPhoneNumberError("Phone number must be 10 digits");
    } else {
      setPhoneNumberError("");
    }

    if (name === "password") {
      setpasswordError("Password must be at least 8 characters long");

      const strength = calculatePasswordStrength(value);
      setPasswordStrength(strength);

      // Show/hide the password strength bar based on the password length
      setShowPasswordStrengthBar(value.length > 0);
    }

    if (name === "email") {
      // Email format validation
      const emailRegex =/^[a-z][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailRegex.test(value)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    try {
      // Dispatch the registerUser action
      const response = await dispatch(registerUser(formData));
      // The rejection will be caught here
      if ("error" in response) {
        const errorMessage =
          response.payload.message || "An error occurred during registration.";

        if (response.payload) {
          Swal.fire({
            icon: "error",
            title: "Registration Failed",
            text: errorMessage,
          }).then(() => {
            window.location.reload();
          });
        }
      } else {
        // Successful registration
        Swal.fire({
          icon: "success",
          title: "Registration Successful!",
          text: "You can now log in with your credentials.",
        }).then(() => {
          navigate("/auth/login");
        });
      }
    } catch (error) {
      // Handle unexpected errors (network issues, etc.)
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: "An unexpected error occurred during registration.",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const isSubmitDisabled =
    Object.values(formData).some((field) => field === "") ||
    formData.phoneNumber.length !== 10 ||
    formData.password.length < 8 ||
    emailError !== "";


  return (
    <div className="main-wrapper authendication-pages">
      {/* Page Content */}
      <div className="content">
        <div className="container wrapper no-padding">
          <div className="row no-margin vph-100">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
              <div className="banner-bg register"></div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
              <div className="dull-pg">
                <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                  <div className="d-flex justify-content-end pt-3">
                    <i
                      className="fa-solid fa-xmark"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      onClick={handleXMark}
                    />
                  </div>
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <header className="text-center">
                      <a href="index.html">
                        <img src={logo} className="img-fluid w-25" alt="Logo" />
                      </a>
                    </header>
                    <div className="shadow-card">
                      <h2>Get Started With Pro Padel</h2>
                      <p>
                        Ignite your sports journey with Pro Padel and get
                        started now.
                      </p>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="user-tab"
                        >
                          {/* Register Form */}
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-mail" />
                                <input
                                  type="text"
                                  name="fullName"
                                  className="form-control"
                                  placeholder="Full Name"
                                  value={formData.fullName}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-mail" />
                                <input
                                  type="email"
                                  name="email"
                                  className={`form-control ${
                                    emailError ? "is-invalid" : ""
                                  }`}
                                  placeholder="Email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  onBlur={handleChange} // Trigger validation on blur
                                  required
                                />
                                {emailError && (
                                  <div className="invalid-feedback">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-user" />
                                <input
                                  type="text"
                                  name="phoneNumber"
                                  className={`form-control ${
                                    phoneNumberError ? "is-invalid" : ""
                                  }`}
                                  placeholder="Phone no"
                                  value={formData.phoneNumber}
                                  onChange={handleChange}
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/[^0-9]/g, "")
                                      .slice(0, 10);
                                  }}
                                  maxLength="10"
                                  required
                                />
                                {phoneNumberError && (
                                  <div className="invalid-feedback">
                                    {phoneNumberError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="pass-group group-img">
                                <i className="toggle-password feather-eye-off" />
                                <input
                                  type="password"
                                  className="form-control pass-input"
                                  placeholder="Password"
                                  name="password"
                                  value={formData.password}
                                  onChange={handleChange}
                                  required
                                />
                                {showPasswordStrengthBar && (
                                  // Inside the JSX, update the password strength div
                                  <div className="password-strength-bar">
                                    <div
                                      className={`password-strength ${
                                        passwordStrength < 0.6
                                          ? "low"
                                          : passwordStrength < 0.9
                                          ? "medium"
                                          : "high"
                                      }`}
                                      style={{
                                        width: `${passwordStrength * 100}%`,
                                      }}
                                    ></div>
                                  </div>
                                )}
                                {formData.password.length > 0 &&
                                  formData.password.length < 8 && (
                                    <div>
                                      <span className="text-danger">
                                        {passwordError}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <button
                              className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                              type="submit"
                              disabled={isSubmitDisabled}
                            >
                              Create Account
                              <i className="feather-arrow-right-circle ms-2" />
                            </button>
                          </form>
                          {/* /Register Form */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coach"
                          role="tabpanel"
                          aria-labelledby="coach-tab"
                        ></div>
                      </div>
                      <div className="text-center">
                        <p>
                          Have an account?{" "}
                          <Link to="/auth/login" className="text-primary">
                            Sign In!
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
};

export default SignUp;

import React from "react";
import Slider from "react-slick";

const TimeSlotSelector = ({
  timeSlots,
  handleTimeSlotClick,
  isTimeSlotSelected,
  isTimeSlotBooked,
  isPastTimeSlot,
}) => {

  const filteredTimeSlots = timeSlots.filter((timeSlot) => !isPastTimeSlot(timeSlot));

  const timeslots_settings = {
    infinite: false,
    slidesToShow: 3,
    speed: 500,
    rows: timeSlots?.length > 5 ? 2 : 1,
    slidesPerRow: 1,
    initialSlide: 0,
    slidesToScroll: filteredTimeSlots.length > 6 ? 3 : 1,
  };

  return (
    <Slider {...timeslots_settings}>
      {timeSlots.slice(0, -1).map((timeSlot, index) => {
        if (isPastTimeSlot(timeSlot)) {
          return null;
        }
        return (
          <div
            key={index}
            onClick={
              isTimeSlotBooked(timeSlot)
                ? null
                : () => handleTimeSlotClick(index)
            }
          >
            <div
              className={`time-slot ${
                isTimeSlotSelected(timeSlot) ? "checked" : ""
              } ${isTimeSlotBooked(timeSlot) ? "deactive" : "active"}`}
            >
              <span className="time-slot-start">{timeSlot}</span>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default TimeSlotSelector;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseURL from '../../utils/axios';

// Async thunk for fetching booking details
export const fetchBookingDetails = createAsyncThunk(
  'bookings/fetchBookingDetails',
  async () => {
    const response = await baseURL.get(`${process.env.REACT_APP_BASE_URL}/getBookingDetails`);
    return response.data;
  }
);

// Async thunk for fetching user details
export const fetchUserDetails = createAsyncThunk(
  'user/fetchUserDetails',
  async () => {
    const response = await baseURL.get(`${process.env.REACT_APP_BASE_URL}/profile`);
    return response.data;
  }
);

// Booking slice
const bookingSlice = createSlice({
  name: 'bookings',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookingDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBookingDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userDetails: {},
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { reducer: bookingReducer } = bookingSlice;
export const { reducer: userReducer } = userSlice;

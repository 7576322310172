// gameSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//get All Games
export const getAllGames = createAsyncThunk("game/getAllGames", async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallGame`);
  return response.data.filter((game) => game.status);
});

// get All Court By Game ID
export const getAllGameCourtsById = createAsyncThunk(
  "game/getAllGameCourtsById",
  async (gameId , { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllGameCourt/${gameId}`
      );
      return response.data.filter((game) => game.status);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  games: [],
  courts: [],
  status: "idle",
  error: null,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // all games cases
      .addCase(getAllGames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.games = action.payload;
      })
      .addCase(getAllGames.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // all court by game id cases
      .addCase(getAllGameCourtsById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllGameCourtsById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courts = action.payload;
      })
      .addCase(getAllGameCourtsById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ;
      });
  },
});

export default gameSlice.reducer;

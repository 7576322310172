import React, { useState } from "react";
import TimeSlotSelector from "./TimeSlotSelector";

const CourtAccordion = ({
  courts,
  expandedCourt,
  onAccordionToggle,
  selectedDate,
  handleTimeSlotClick,
  isTimeSlotBooked,
  isTimeSlotSelected,
  isPastTimeSlot,
  timeSlots,
}) => {

  const [loading, setLoading] = useState(false);

  const handleCourtSelection = (court) => {
    setLoading(true); 
    onAccordionToggle(court); 
    setTimeout(() => {
      setLoading(false); 
    }, 500); 
  };


  return ( 
    <>
      {courts.map((court) => (
        <div className="accordion" id="accordionPanel" key={court._id}>
          <div className="accordion-item mb-4" id="overview">
            <h4 className="accordion-header" id={`panelsStayOpen-${court._id}`}>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#panelsStayOpen-collapse-${court._id}`}
                aria-expanded={expandedCourt === court}
                aria-controls={`panelsStayOpen-collapse-${court._id}`}
                onClick={() => handleCourtSelection(court)} 
              >
                {court.court_Name}
              </button>
            </h4>
            <div
              id={`panelsStayOpen-collapse-${court._id}`}
              className={`accordion-collapse collapse ${
                expandedCourt === court ? "show" : ""
              }`}
              aria-labelledby={`panelsStayOpen-${court._id}`}
            >
          <div className="accordion-body">
                {loading ? ( // Display loading indicator if loading is true
                  <div class="spinner-border text-primary mt-5 mb-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                ) : (
                  <div className="text show-more-height px-2">
                    <TimeSlotSelector
                      selectedDate={selectedDate}
                      timeSlots={timeSlots}
                      handleTimeSlotClick={handleTimeSlotClick}
                      isTimeSlotBooked={isTimeSlotBooked}
                      isTimeSlotSelected={isTimeSlotSelected}
                      isPastTimeSlot={isPastTimeSlot}
                    />
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CourtAccordion;

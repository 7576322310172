
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseURL from '../../utils/axios';



export const tempBooking = createAsyncThunk('booking/bookAppointment', async (bookingData, thunkAPI) => {
  const formattedTimeSlots = bookingData.selectedTimeSlots.map((time) => ({ time }));
  const requestBody = {
    date: bookingData.selectedDate,
    userId: bookingData.userId,
    courtId: bookingData.selectedCourtId,
    timeSlot: formattedTimeSlots,
  };

  try {
    const response = await baseURL.post(`/tempBooking`, requestBody);
    return response.data; 
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});


// Slice for booking state
export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tempBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tempBooking.fulfilled, (state) => {
        state.loading = false;
        // You can perform additional actions on success if needed
      })
      .addCase(tempBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the action creators
export const selectBookingLoading = (state) => state.booking.loading;
export const selectBookingError = (state) => state.booking.error;



// Export the reducer for use in the store
export default bookingSlice.reducer;

// couponSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseURL from '../../utils/axios';


export const applyCoupon = createAsyncThunk(
  'coupon/applyCoupon',
  async (couponName, {rejectWithValue}) => {
    try {
      const response = await baseURL.get(
        `${process.env.REACT_APP_BASE_URL}/applyCoupon?couponName=${couponName}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    couponInfo: null,
    error: null,
    status: 'idle',
  },
  reducers: {
    resetCoupon: (state) => {
      state.couponInfo = null;
      state.error = null;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyCoupon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.couponInfo = action.payload;
        state.error = null
      })
      .addCase(applyCoupon.rejected, (state, action) => {
        state.status = 'failed';
        state.couponInfo = null;
        state.error = action.payload;
      });
  },
});

export const { resetCoupon } = couponSlice.actions;

export default couponSlice.reducer;

import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import Breadcrumb from "../Components/Common/Breadcrumb";
import img1 from "../assets/img/court.jpg";
import img3 from "../assets/img/about/pickleballd.jpg";

const Gallery = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={"Gallery"} page={"Gallery"} />
      <div className="container">
        <div className="row mt-5 mb-5 gallery-row">
          <div className="col-lg-4">
            <img src={img1} alt="img" />
          </div>
          <div className="col-lg-4">
            <img src={img3} alt="img" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Breadcrumb from "../Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { tempBooking } from "../../Redux/Slices/bookingSlice";
import { applyCoupon, resetCoupon } from "../../Redux/Slices/couponSlice";
import { createCheckout } from "../../Redux/Slices/checkoutSlice";
import moment from "moment";
import { fetchUserDetails } from "../../Redux/Slices/profileSlice";
// import baseURL from "../../Api/api";

const Checkout = () => {
  const location = useLocation();
  const bookingData = location.state;

  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState();
  const [isStaticCouponApplied, setisStaticCouponApplied] = useState(false)
  const [isMorningCouponApplied, setisMorningCouponApplied] = useState(false);
  const [morningCouponData, setmorningCouponData] = useState();
  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserDetails());
    return () => {
      dispatch(resetCoupon());
    };
  }, [dispatch]);

  // async function getMorningCoupon() {
  //   try {
  //     const response = await baseURL.get(
  //       `/applyCoupon?timeSlot=${
  //         bookingData?.selectedTimeSlots[
  //           bookingData?.selectedTimeSlots.length - 1
  //         ]
  //       }`
  //     );
  //     if (response?.status === 200 && !response?.data === false) {
  //       setmorningCouponData(response?.data);
  //       setisMorningCouponApplied(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  function checkTime(startTime, endTime, matchTime) {
    const morStartTime = moment(startTime, "hh:mm A");
    const morEndTime = moment(endTime, "hh:mm A");
    const targetTime = moment(matchTime, "hh:mm A");
    let morningTime =
      targetTime.isSameOrAfter(morStartTime) &&
      targetTime.isSameOrBefore(morEndTime);

    if (morningTime) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (bookingData.selectedGame === "Pickle Ball") {
      let durationTime = checkTime(
        "06:00 AM",
        "08:30 PM",
        bookingData?.selectedTimeSlots[
          bookingData?.selectedTimeSlots.length - 1
        ]
      );
      if (durationTime) {
        setDiscountedAmt(150 * bookingData?.selectedTimeSlots.length);
        setisStaticCouponApplied(true)
      }
    }

    if (bookingData.selectedGame === "Padel Tennis") {
      let durationTime = checkTime(
        "06:00 AM",
        "09:30 AM",
        bookingData?.selectedTimeSlots[
          bookingData?.selectedTimeSlots.length - 1
        ]
      );
      if (durationTime) {
        setDiscountedAmt(150 * bookingData?.selectedTimeSlots.length);
        setisStaticCouponApplied(true)
      }
    }
  }, [bookingData]);

  // useEffect(() => {
  //   if (bookingData) {
  //     const startTime = moment("06:00 AM", "hh:mm A");
  //     const endTime = moment("08:30 PM", "hh:mm A");
  //     const targetTime = moment(bookingData?.selectedTimeSlots[0], "hh:mm A");

  //     let valid =
  //       targetTime.isSameOrAfter(startTime) &&
  //       targetTime.isSameOrBefore(endTime);
  //     if (valid) {
  //       getMorningCoupon();
  //     }
  //   }
  // }, []);

  const loading = useSelector((state) => state.booking.loading);

  const [discountAmt, setDiscountedAmt] = useState();


  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const couponDetails = useSelector((state) => state?.coupon?.couponInfo);

  const ApplyPromoCode = async () => {
    const response = await dispatch(applyCoupon(promoCode));

    setPromoCode("");
    if (response?.error) {
      setDiscountedAmt(null);
      Swal.fire({
        icon: "question",
        title: "Coupon Error",
        text: response?.payload?.message,
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Coupon Applied",
        text: `Promo Code Applied`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    if (couponDetails) {
      discountedAmt();
    }
  }, [couponDetails]);

  // useEffect(() => {
  //   if (isMorningCouponApplied) {
  //     MorningdiscountedAmt();
  //   }
  // }, [morningCouponData]);

  function discountedAmt() {
    if (couponDetails?.couponType === "valueDiscount") {
      setDiscountedAmt(couponDetails?.couponAmount);
    } else if (couponDetails?.couponType === "percentageDiscount") {
      let discountedPrice = (
        (bookingData?.totalAmount * couponDetails.couponAmount) /
        100
      ).toFixed(2);
      setDiscountedAmt(discountedPrice);
    }
  }
  // function MorningdiscountedAmt() {
  //   if (morningCouponData?.couponType === "valueDiscount") {
  //     let amount =
  //       morningCouponData?.couponAmount * bookingData?.selectedTimeSlots.length;
  //     setDiscountedAmt(amount);
  //   } else if (morningCouponData?.couponType === "percentageDiscount") {
  //     let discountedPrice = (
  //       (bookingData?.totalAmount * morningCouponData.couponAmount) /
  //       100
  //     ).toFixed(2);
  //     setDiscountedAmt(discountedPrice);
  //   }
  // }
  // console.log(morningCouponData)
  function OrderTotal() {
    if (discountAmt) {
      const finalAmount = bookingData?.totalAmount - discountAmt;
      return finalAmount;
    } else {
      return bookingData?.totalAmount;
    }
  }

  const handlePay = async () => {
    try {
      const response = await dispatch(
        tempBooking({
          selectedDate: bookingData.selectedDate,
          selectedCourtId: bookingData.selectedCourtId,
          selectedTimeSlots: bookingData.selectedTimeSlots,
          userId : userDetails?._id
          // userData,
        })
      );

      if (response?.error) {
        Swal.fire({
          icon: "error",
          title: `${response?.payload?.message}`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        const result = await Swal.fire({
          title: "Proceed To Pay",
          text: response?.payload?.message,
          showCancelButton: true,
          confirmButtonText: "Pay",
        });

        if (result.isConfirmed) {
          try {
            const selectedTimeSlots = response?.payload?.tempSlotIds;
            const checkoutResponse = await dispatch(
              createCheckout({
                courtId: bookingData.selectedCourtId,
                couponId: isMorningCouponApplied
                  ? morningCouponData?._id
                  : couponDetails?._id,
                date: bookingData.selectedDate,
                timeSlot: selectedTimeSlots,
              })
            );

            const checkoutLink = await checkoutResponse?.payload;

            if (checkoutLink) {
              // window.location.href = checkoutLink;
              window.location.replace(checkoutLink);
              // navigate(checkoutLink)
              // window.open(checkoutLink, '_blank');
            } else {
              Swal.fire({
                icon: "error",
                title: `Server Error`,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          } catch (checkoutError) {
            // Handle checkout error
            Swal.fire({
              icon: "error",
              title: `${checkoutError?.message}`,
              showConfirmButton: false,
              timer: 2000,
            });
          } finally {
            // Dispatch the resetCoupon action at the end
            await dispatch(resetCoupon());
          }
        }
      }
    } catch (error) {
      // Handle unexpected errors
      Swal.fire({
        icon: "error",
        title: "Error Booking Slots",
      });
    }
  };


  
  return (
    <>
      <Navbar />
      <Breadcrumb title="Checkout" page={`checkout`} />
      <section className="booking-steps py-30">
        <div className="container">
          <ul className="d-xl-flex justify-content-center align-items-center">
            <li>
              <h5>
                <a>
                  <span>1</span>Time &amp; Date
                </a>
              </h5>
            </li>
            <li className="active">
              <h5>
                <a className="payment">
                  <span>2</span>Checkout
                </a>
              </h5>
            </li>
          </ul>
        </div>
      </section>
      <div className="content">
        <div className="container">
          <section>
            <div className="text-center mb-40">
              <h3 className="mb-1">Payment</h3>
              <p className="sub-title">
                Securely make your payment for the booking. Contact support for
                assistance.
              </p>
            </div>

            <div className="row checkout">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="card booking-details">
                  <h3 className="border-bottom">Order Summary</h3>
                  <ul className="checkout-ul">
                    <li>
                      <i className="fa-solid fa-user"></i>
                      {userDetails?.fullName}
                    </li>
                    <li>
                      <i className="fa-solid fa-address-book"></i>
                      {userDetails?.phoneNumber}
                    </li>
                    <li>
                      <i className="fa-solid fa-calendar-days"></i>
                      {bookingData?.selectedDate}
                    </li>
                    <li>
                      <i className="fa-solid fa-hourglass-start"></i>
                      {bookingData?.selectedDuration} mins
                    </li>
                    <li>
                      <i className="fa-solid fa-clock custom"></i>
                      {bookingData?.timeSlotsText}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <aside className="card payment-modes">
                  <h3 className="border-bottom">Checkout</h3>
                  {/* promo Section */}
                  <label htmlFor="">
                    {isMorningCouponApplied
                      ? "Promo Code Applied :"
                      : "Apply Promo Code :"}{" "}
                  </label>
                  <div class="input-group pt-2">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        isMorningCouponApplied
                          ? morningCouponData?.couponName
                          : promoCode
                      }
                      readOnly={isMorningCouponApplied}
                      onChange={(e) => setPromoCode(e.target.value)}
                      aria-label="Amount (to the nearest dollar)"
                    />
                    <div class="input-group-append">
                      <button
                        className={`p-2 px-3 ${
                          !promoCode ? "btn-dark" : "btn-primary"
                        }`}
                        onClick={ApplyPromoCode}
                        disabled={!promoCode || isStaticCouponApplied}
                      >
                        Apply
                      </button>
                    </div>
                  </div>

                  <div className="order-total ">
                    <div className="d-flex justify-content-between align-items-center ">
                      <h5>Sub Total</h5>
                      <h5>{bookingData?.totalAmount}</h5>
                    </div>
                    {/* {isMorningCouponApplied && (
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <h5>{morningCouponData?.couponName} : applied</h5>
                        <h5>- {discountAmt}</h5>
                      </div>
                    )} */}
                    {couponDetails && (
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <h5>{couponDetails?.couponName} : applied</h5>
                        <h5>- {discountAmt}</h5>
                      </div>
                    )}
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Order Total</h5>
                      <h5>{OrderTotal()}</h5>
                    </div>
                  </div>
                  <div className="form-check d-flex justify-content-start align-items-center policy">
                    <div className="d-inline-block">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                        id="policy"
                      />
                    </div>
                    <label className="form-check-label" htmlFor="policy">
                      By clicking this, I agree to ProPadel{" "}
                      <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="/t&c" target="_blank">
                        Terms & Conditions
                      </a>
                    </label>
                  </div>
                  <div className="d-grid btn-block">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!isCheckboxChecked}
                      onClick={handlePay}
                    >
                      {loading ? "Processing..." : "Proceed to Pay"}
                    </button>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Checkout;

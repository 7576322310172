import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../constants";

//Async thunk Register
export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}`,
        userData
      );
      const { user, token } = response.data;
      return { user, token };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for login
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/login`,
        credentials
      );
      const { user, token } = response.data;
      return { user, token };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/forgetPassword`,
        { email }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//async thunk for resetpassword

export const resetPasswordAsync = createAsyncThunk(
  "resetPassword/resetPasswordAsync",
  async ({ token, password, confirmPassword }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_AUTH_URL}/resetPassword?token=${token}`,
        {
          password,
          confirmPassword,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: "idle",
    error: null,
    token : localStorage.getItem(TOKEN) || null
  },
  reducers: {
    logoutUser: (state) => {
      state.token = null;
      localStorage.clear()
    }
  },
  extraReducers: (builder) => {
    builder
      // register
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload
          ? action.payload.message
          : "Registration failed";
      })
      // login
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
        state.token = action.payload.token
        localStorage.setItem(TOKEN, action.payload.token);
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload ? action.payload.message : "Login failed";
      })
      // forget password
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload
          ? action.payload.message
          : "Forgot password failed";
      })
      // reset password
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload
          ? action.payload.message
          : "Reset password failed";
      });
  },
});

export const { logoutUser , setCredentials} = authSlice.actions;
export default authSlice.reducer;
               
export const testimonialData = [
  {
    rating: 5.0,
    review:
      "The facilities are top-notch, the staff is friendly and accommodating It's the perfect place to hone my skills",
    name: "Rahul S.",
    sport: "Padel Tennis",
  },
  {
    rating: 5.0,
    review:
    "I've been playing padel tennis at Propadel Ahmedabad for months. It's the city's best facility!",
    name: "Priya M.",
    sport: "Pickle Ball",
  },
  {
    rating: 5.0,
    review:
    "The staff handled every event detail, from bookings to rentals, with excellence.",
    name: "Raj D.",
    sport: "Padel Tennis",
  },
  {
    rating: 5.0,
    review:
      "The community events and tournaments are well-organized and a lot of fun.",
    name: "Sahil P.",
    sport: "Pickle Ball",
  },
  {
    rating: 5.0,
    review:
      "The atmosphere is welcoming, and I feel motivated to continue my padel tennis journey.",
    name: "Neha D.",
    sport: "Padel Tennis",
  },
];

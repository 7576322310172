import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Slices/authSlice';
import gameSlice from './Slices/gameSlice';
import slotSlice from "./Slices/slotSlice"
import bookingSlice from './Slices/bookingSlice';
import contactSlice from './Slices/contactSlice';
import couponSlice from './Slices/couponSlice';
import checkoutSlice from './Slices/checkoutSlice';
import  { bookingReducer, userReducer } from './Slices/profileSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    allGames: gameSlice,
    slots: slotSlice,
    booking: bookingSlice,
    contact: contactSlice,
    coupon: couponSlice,
    checkout: checkoutSlice,
    bookings: bookingReducer,
    user: userReducer,
  },
});

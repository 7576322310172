
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseURL from "../../utils/axios";


export const createCheckout = createAsyncThunk(
  "checkout/createCheckout",
  async ({ courtId, couponId, date,timeSlot }) => {

    try {
      const response = await baseURL.post(
        `/createCheckout`,
        {
          courtId,
          couponId,
          date,
          timeSlot
        }
      );
      return response.data;
    } catch (error) {
      console.log(error, "checkout error --");
    }
  }
);


const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCheckout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCheckout.fulfilled, (state) => {
        state.loading = false;
        // Handle successful API call, if needed
      })
      .addCase(createCheckout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default checkoutSlice.reducer;

import { Navigate } from "react-router-dom";
import About from "./Components/About/About";
import Login from "./Components/Auth/Login";
import SignUp from "./Components/Auth/SignUp";
import Booking from "./Components/Booking/Booking";
import Checkout from "./Components/Checkout/Checkout";
import Contact from "./Components/Contact/Contact";
import Home from "./Components/Home/Home";
import Profile from "./Components/Profile/Profile";

import ForgotPwd from "./Components/Auth/ForgotPwd";
import ResetPwd from "./Components/Auth/ResetPwd";
import Gallery from "./Gallery/Gallery";
import Rule from "./Components/Rules/Rule";
import MyBookings from "./Components/Profile/MyBookings";
import Privacy from "./Components/Policy.jsx/Privacy";
import Refund from "./Components/Policy.jsx/Refund";
import TermsCond from "./Components/Policy.jsx/TermsCond";
import GameDetails from "./Components/Details/GameDetails";
import { useSelector } from "react-redux";


const PrivateRoute = ({ element}) => {
  const { token } = useSelector(state => state.auth);
  if (token) {
    return <Navigate to="/" replace/>;
  }
  return element;
};

const ProtectedRoute = ({ element }) => {
  const { token } = useSelector(state => state.auth);
  if (!token) {
    return <Navigate to="/" replace/>;
  }
  return element;
};

const routes = [
  // private route
  { path: "/auth/register", element: <PrivateRoute element={<SignUp />} /> },
  { path: "/auth/login", element: <PrivateRoute element={<Login />} /> },
  { path: "/auth/forgotPassword", element: <PrivateRoute element={<ForgotPwd />} /> },
  { path: "/auth/resetPassword", element: <PrivateRoute element={<ResetPwd />} /> },
  
  // public route
  { path: "/", element: <Home /> },
  { path: "/privacy-policy", element: <Privacy /> },
  { path: "/refund-policy", element: <Refund /> },
  { path: "/t&c", element: <TermsCond /> },
  { path: "/gallery", element: <Gallery /> },
  { path: "/gameRules", element: <Rule /> },
  { path: "/about", element: <About /> },
  { path: "/game/:game/:id", element: <GameDetails /> },
  { path: "/contact", element: <Contact /> },
  
  // protected route
  { path: "/booking/:gamename/:id", element: <ProtectedRoute element={<Booking />} />},
  { path: "/checkout", element: <ProtectedRoute element={<Checkout />} /> },
  { path: "/profile", element: <ProtectedRoute element={<Profile />} /> },
  { path: "/MyBookings", element: <ProtectedRoute element={<MyBookings />} /> }
];

export default routes;

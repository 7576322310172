import React, { useState } from "react";
import { Link} from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../Redux/Slices/authSlice";
import Swal from "sweetalert2";

const ForgotPwd = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const isLoading = useSelector((state) => state.auth.loading === "pending");

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await dispatch(forgotPassword(email));


      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: "Error Sending Mail",
          text: "An error occurred while sending the password reset email.",
        });
      } else if (response && response.payload) {
        // Assuming the API response contains a payload property
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Password reset email sent successfully.",
        });
      } else {
        // Handle the case where the response doesn't contain the expected data
        // console.error("Forgot password failed: Invalid response format");
      }
    } catch (error) {
      // Handle errors, you can log the error or show an error message to the user
      console.error("Forgot password failed:", error.message);
      // Optionally, you can display an error message to the user
      // setError("Forgot password failed. Please check your email.");
    }
  };

  return (
    <div className="main-wrapper authendication-pages">
      <div className="content">
        <div className="container wrapper no-padding">
          <div className="row no-margin vph-100">
            <div className="col-12 col-sm-12 col-lg-6 no-padding">
              <div className="banner-bg login">
                <div className="row no-margin h-100"></div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-6 no-padding">
              <div className="dull-pg">
                <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <div className="d-flex justify-content-end pt-3">
                      {/* <i
                        className="fa-solid fa-xmark"
                        style={{ fontSize: "24px", cursor: "pointer" }}
                        // onClick={handleXMark}
                      /> */}
                    </div>
                    <header className="text-center">
                      <a href="user-dashboard.html">
                        <img src={logo} className="img-fluid w-25" alt="Logo" />
                      </a>
                    </header>
                    <div className="shadow-card">
                      <h2>Forgot Password</h2>
                      <p></p>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="user-tab"
                        >
                          {/* Login Form */}
                          <form onSubmit={handleForgotPassword}>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-user" />
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Your Mail"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                            >
                              {isLoading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Send Mail"
                              )}
                            </button>
                          </form>
                          {/* /Login Form */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coach"
                          role="tabpanel"
                          aria-labelledby="coach-tab"
                        ></div>
                      </div>
                    </div>
                    <div className="bottom-text text-center">
                      <p>
                        Back to {}
                        <Link to="/auth/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPwd;
